<template>
  <div class="text-left" style="font-size: 11px">
    <el-form :model="form" :label-position="'top'" ref="form" size="small">
      <el-form-item prop="username" :label="$t('ifm.username')" :rules="rules.req">
        <el-input v-model="form.username" clearable @keyup.enter.native="loginHandler"/>
      </el-form-item>
      <el-form-item prop="password" :label="$t('ifm.psw')" :rules="rules.req">
        <el-input v-model="form.password" clearable type="password" @keyup.enter.native="loginHandler"/>
      </el-form-item>
      <el-form-item prop="checkTa" label="" :rules="rules.reqCheck" class="checkboxClass">
        <div class="checkboxContents">
          <div clss="item" style="padding-right: 10px">
            <el-checkbox v-model="form.checkTa"></el-checkbox>
          </div>
          <div class="item term">
            <p>Updated 
              <a href="VesselConnect Terms of Use (Barge Owners).pdf" target="_blank" class="underline">Terms of Use</a> and 
              <a href="VesselConnect SaaS Agreement(Barge Owners).pdf" target="_blank" class="underline">Service Agreement</a>
              with effect from 1st Oct 2024.
              Please make sure you have read and agree.
            </p>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="bottom">
      <div>
        <el-button class="loginBtn" type="success" @click="loginHandler" size="small">{{ $t("btn.signIn") }}</el-button>
      </div>
      <div>
        <el-link :underline="false" class="link" @click="forgetHandler">Forgot Password?</el-link>
      </div>
      <div>
        <div class="crossline"><span>or</span></div>
      </div>
      <div>
        <el-button class="qrBtn" type="success" plain @click="qr" size="small">Scan QR Code Login</el-button>
      </div>
    </div>
    <el-dialog
      title="Login"
      :visible.sync="dialogVisible"
      :before-close="clearIntervalsAndCloseDialogs"
      width="30%">
      <span>Please confirm your login on the Marine Vessel Pass app (iOS/Android).</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clearIntervalsAndCloseDialogs">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Login Rejected"
      :visible.sync="rejectedDialogVisible"
      :before-close="clearIntervalsAndCloseDialogs"
      width="30%">
      <span style="color:#F56C6C">Your login attempt on the Marine Vessel Pass app (iOS/Android) was rejected.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clearIntervalsAndCloseDialogs">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapCheckCode, getPasswordAuthenticatorStatus } from "@/services/user";
import { setToken, setMvpToken, setCheckTs, getCheckTs, setReadBargeLetter } from "../utils/auth";

export default {
  name: 'LoginBargeAndOperator',
  data() {
    return {
      form: {
        username: "",
        password: "",
        checkTa: null
      },
      rules: {
        req: {
          required: true,
          message: this.$t('tips.sr'),
          trigger: ['change', 'blur'],
        },
        reqCheck: {
          required: true,
          message: 'You are required to agree to Terms of Use and Service Agreement before you can login',
          trigger: ['change', 'blur'],
        }
      },
      dialogVisible: false,
      rejectedDialogVisible: false,
      getPasswordAuthenticatorLoop: null
    }
  },
  watch: {
    'form.username': function (newVal) {
      const checkTs = getCheckTs(newVal);
      if (checkTs == 'true') {
        this.form.checkTa = true;
      } else {
        this.form.checkTa = null;
      }
    },
    'form.checkTa': function (newVal) {
      if (newVal === false) {
        this.form.checkTa = null;
      }
    }
  },
  created() {
    setReadBargeLetter(false)
  },
  methods: {
    loginHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('load', true )
          const user = { username: this.form.username, password: this.form.password, isMvp: true };
          
          this.$store.dispatch("goLogin", user).then((res) => {
            delete user["isMvp"];
            if (res.token && !res.twoFactorEnabled) {
              this.$message.success(this.$t("status.success"));
              setToken(res.token);
              setMvpToken(res.mvpToken);
              setReadBargeLetter(true);
              this.$store.dispatch("getUserInfo", true).then((url) => {
                this.$router.push(url);
              });
              setCheckTs(this.form.username);
            } else if (res.twoFactorEnabled) {
              this.$prompt(this.$t("ifm.pevc"), {
                confirmButtonText: this.$t("btn.comfirm"),
                cancelButtonText: this.$t("btn.cancel"),
                inputPattern: /\S/,
                inputErrorMessage: this.$t("tips.sr"),
                beforeClose: (action, instance, done) => {
                  if (action == "confirm") {
                    mapCheckCode({
                      code: instance.inputValue,
                      userName: res.username,
                    }).then((resA) => {
                      if (resA.code == 200) {
                        this.$message.success(this.$t("status.success"));
                        done();
                        setToken(resA.token);
                        this.$store.dispatch("getUserInfo", true).then((url) => {
                          this.$router.push(url);
                        });
                        setReadBargeLetter(true);
                        setCheckTs(this.form.username);
                      } else {
                        this.$message.error(this.$t("ifm.error"));
                      }
                    });
                  } else {
                    done();
                  }
                },
              });
            } else if (res.passwordAuthenticatorEnabled) {
              this.dialogVisible = true;
              this.getPasswordAuthenticatorLoop = setInterval(() => {
                getPasswordAuthenticatorStatus({ username: encodeURIComponent(res.username) }).then((resA) => {
                  if (resA.code == 200 && resA.data.flag == 1) {
                    this.$message.success(this.$t("status.success"));
                    setToken(resA.token);
                    this.$store.dispatch("getUserInfo", true).then((url) => {
                      this.$router.push(url);
                    });
                    setReadBargeLetter(true);
                    setCheckTs(this.form.username);
                    this.clearIntervalsAndCloseDialogs(() => {});
                  } else if (resA.code == 200 && resA.data.flag == 2) {
                    this.clearIntervalsAndCloseDialogs(() => {});
                    this.rejectedDialogVisible = true;
                  } else if (resA.code == 500) {
                    this.clearIntervalsAndCloseDialogs(() => {});
                  }
                }).catch(err => {
                  this.clearIntervalsAndCloseDialogs(() => {});
                });
              }, 1000);
            }
          })
          .finally(() => {
            this.$emit('load', false )
          });
        } 
      });
    },
    clearIntervalsAndCloseDialogs(done) {
      clearInterval(this.getPasswordAuthenticatorLoop);
      this.dialogVisible = false;
      this.rejectedDialogVisible = false;
      done();
    },
    forgetHandler() {
      const validateEmail = (email) => {
        return String(email).toLowerCase().match( /^[^\s@]+@[^\s@]+\.[^\s@]+$/ );
      };

      let parameter = ''
      if (this.form.username && validateEmail(this.form.username)) {
        parameter = '&email=' + this.form.username
      }
      window.open(process.env.VUE_APP_AUTH_HOST + '/login?forgotPassword=1' + parameter);
    },
    qr() {
      this.$emit('qr')
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-form-item__label {
  font-size: 11px !important;
  line-height: 14px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}
::v-deep .el-form-item__error {
  font-size: 11px !important;
  padding-top: 0px !important;
}
::v-deep .el-form-item {
  margin-bottom: 14px;
}
::v-deep .el-form-item__content {
  line-height: 36px;
}
.checkboxClass ::v-deep .el-form-item__content {
  line-height: 16px;
}
.checkboxContents{
  width: 100%;
  display: flex;
  justify-content: start;

  .item {
    display: inline-block;
    vertical-align: top;
  }
}
::v-deep .el-checkbox__label  {
  font-size: 10px !important;
  line-height: 14px;
  color: #82889C;
  font-weight: 400;
}
.term {
  font-size: 10px; 
  line-height: 13px;
}
.underline {
  text-decoration: underline;
}
.bottom {
  text-align: center;
  padding: 10px 0px;
}
.loginBtn {
  width: 200px;
}
.qrBtn {
  width: 200px;
  background-color: white;
}
.link {
  font-size: 11px;
  color: #82889C;
  font-weight: 400;
  padding: 10px 0;
}
.crossline {
  width: 100px; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 10px auto 20px; 
} 

.crossline span { 
  background:#fff; 
  padding:0 10px; 
}
</style>